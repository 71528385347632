import React, { useState, useEffect } from "react";
import RetroButton from "../components/RetroButton/RetroButton";
import { Heart } from "lucide-react";
import confetti from 'canvas-confetti';

const Valentine: React.FC = () => {
  const [noCount, setNoCount] = useState(0);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [showSuccess, setShowSuccess] = useState(false);

  // Add floating hearts
  const [hearts, setHearts] = useState<Array<{ id: number; x: number; y: number; size: number }>>([]);

  const phrases = [
    "No",
    "Are you sure?",
    "Really sure?",
    "I will lick your toe!",
    "ALL YOUR TOES!",
    "Let you smell my armpits !!!",
    "BABY WHAT???!?",
    "Pookie :(",
    "My heart is broken :((((",
    "NO MORE LAPDANCE FOR YOU",
    "if the world was endiiiing",
    "i want to be next to youuuuu",
    "huh ??",
    "nuh huh",
    "say YES",
    "I WILL COOK FOR YOU",
    "WHAT DO YOU MEAN IT MAKES IT WORSE ????",
    "stop being just a girl",
    "i can chocke you for real",
    "NO RAPÉÉÉ",
  ];

  useEffect(() => {
    const createHeart = () => {
      const heart = {
        id: Math.random(),
        x: Math.random() * 100,
        y: -20,
        size: Math.random() * (30 - 15) + 15,
      };
      setHearts(prev => [...prev, heart]);
    };

    const interval = setInterval(createHeart, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const moveHearts = setInterval(() => {
      setHearts(prev => 
        prev.map(heart => ({
          ...heart,
          y: heart.y + 1,
        })).filter(heart => heart.y < 100)
      );
    }, 50);

    return () => clearInterval(moveHearts);
  }, []);

  const handleNoClick = (e: React.MouseEvent) => {
    // Prevent default button behavior
    e.preventDefault();
    
    const newX = Math.random() * (window.innerWidth - 100);
    const newY = Math.random() * (window.innerHeight - 100);
    setPosition({ x: newX, y: newY });
    setNoCount(noCount + 1);
  };

  const handleYesClick = () => {
    setShowSuccess(true);
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 }
    });
  };

  if (showSuccess) {
    return (
      <div className="h-screen flex flex-col items-center justify-center bg-pink-50 relative overflow-hidden">
        <div className="text-center z-10">
          <h1 className="text-4xl md:text-6xl font-bold text-pink-600 mb-8 animate-bounce">
            Yay! ❤️
          </h1>
          <p className="text-xl text-pink-500 mb-4">
            I knew you would say yes! Happy Valentine's Day, Pien! 🌹
          </p>
          <div className="mt-8">
            <img 
              src="/images/couple-photo.jpg"
              alt="Us" 
              className="rounded-lg shadow-lg max-w-sm mx-auto animate-frog-bounce"
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="h-screen flex flex-col items-center justify-center bg-pink-50 relative overflow-hidden">
      {/* Floating hearts */}
      {hearts.map(heart => (
        <div
          key={heart.id}
          className="absolute text-pink-300 animate-pulse"
          style={{
            left: `${heart.x}%`,
            top: `${heart.y}%`,
            fontSize: `${heart.size}px`,
          }}
        >
          ❤️
        </div>
      ))}
      
      <div className="text-center z-10">
        <h1 className="text-4xl md:text-6xl font-bold text-pink-600 mb-8">
          Will you be my Valentine, Pien? 🌹
        </h1>
        <div className="flex flex-col items-center gap-4">
          <RetroButton
            icon={<Heart className="w-5 h-5" />}
            onClick={handleYesClick}
            className="bg-pink-500 hover:bg-pink-600 text-blacgray-600 px-8 py-4 text-xl"
          >
            Yes! ❤️
          </RetroButton>
          
          <button
            className="bg-red-500 hover:bg-red-600 text-white px-8 py-4 rounded-lg text-xl transition-all duration-200"
            style={{
              position: "absolute",
              left: `${position.x}px`,
              top: `${position.y}px`,
              transform: noCount > 0 ? "scale(0.8)" : "scale(1)",
            }}
            onClick={handleNoClick}
          >
            {phrases[Math.min(noCount, phrases.length - 1)]}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Valentine; 